import {
  createApp
} from 'vue'
import App from './App.vue'
import * as EvaIcons from '@stefandesu/eva-icons-vue'
import router from './router'
import ActionCableVue from 'actioncable-vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueApexCharts from 'vue3-apexcharts'
import vClickOutside from 'click-outside-vue3'
import vSelect from 'vue-select'
import Maska from 'maska'
import i18n from './i18n'
import AppLoaderBar from '@/components/app-loader-bar'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@vueform/slider/themes/default.css'
import './assets/scss/custom/plugins/mermaid.min.css'
import 'vue-select/dist/vue-select.css'

import store from './state/store'
import { axios, VueAxios } from './axios'

import './assets/scss/app.scss'

const websocketOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: 'http://localhost:8082/',
  connectImmediately: false
}

createApp(App)
  .use(store)
  .use(i18n)
  .use(EvaIcons)
  .use(router)
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(Maska)
  .use(ActionCableVue, websocketOptions)
  .use(VueAxios, axios)
  .component('v-select', vSelect)
  .component('app-loader-bar', AppLoaderBar)
  .mount('#app')
