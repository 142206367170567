import { createWebHistory, createRouter } from 'vue-router'

import routes from './routes'
import { getToken } from '../storage'

const router = createRouter({
  history: createWebHistory(),
  routes,
  // mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['login', 'logout', 'lock-screen']
  const authpage = !publicPages.includes(routeTo.name)

  if (authpage && !getToken()) {
    return next({ name: 'login' })
  }

  next()
})

export default router
