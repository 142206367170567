import axios from 'axios'
import VueAxios from 'vue-axios'
import { getToken } from './storage'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const onResponseSuccess = (response) => {
  return response
}

const onResponseError = error => {
  return Promise.reject(error)
}

instance.interceptors.request.use(
  function (config) {
    config.headers.Accept = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  onResponseSuccess,
  onResponseError
)

export {
  instance as axios,
  VueAxios
}
