import { createI18n } from 'vue-i18n'

const numberFormats = {
  'pt-BR': {
    currency: {
      style: 'currency', currency: 'BRL', notation: 'standard'
    }
  }
}

const datetimeFormats = {
  'pt-BR': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    info: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
  }
}

const i18n = createI18n({
  locale: 'pt-BR',
  numberFormats,
  datetimeFormats
})

export default i18n
