<template>
  <div class="progress progress-sm mb-4">
    <div
      v-if="loading"
      class="progress-bar"
      role="progressbar"
      style="width: 100%"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
export default {
  name: 'AppLoaderBar',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.progress-bar {
  background-image: linear-gradient(45deg,
      rgba(255,255,255,.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255,255,255,.15) 50%,
      rgba(255,255,255,.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
    animation: percentRuler 1s linear infinite;
}

@keyframes percentRuler {
  0% { background-position: 1rem 0; }
  100% { background-position: 0 0; }
}
</style>
